import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intl';
import 'intl/locale-data/jsonp/en';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import 'scroll-behavior-polyfill';
import 'delayed-scroll-restoration-polyfill';
import { ResizeObserver } from '@juggle/resize-observer';
import * as Sentry from '@sentry/react';
import { enableAllPlugins } from 'immer';
import { AppRegistry } from 'react-native';
import scrollIntoView from 'scroll-into-view-if-needed';
import { AppLoader } from './components/app-loader';
import * as serviceWorker from './serviceWorker';

enableAllPlugins();

const SCROLL_RESET_TIMEOUT_MILLIS = 500;

if ('ResizeObserver' in window === false) {
    window.ResizeObserver = ResizeObserver;
}

const IGNORED_ERROR_REGEXES = [
    /ResizeObserver loop limit exceeded/,
    /A mutation operation was attempted on a database that did not allow mutations/,
];

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    beforeSend(event) {
        const values = event?.exception?.values;

        if (values?.length) {
            const error = values[0];
            const value = error?.value;

            if (
                value &&
                typeof value === 'string' &&
                IGNORED_ERROR_REGEXES.find((regex) => value.match(regex))
            ) {
                return null;
            }
        }

        return event;
    },
});

let scrollResetTimeout = null;
// Reset scroll to fix iOS Safari keyboard behavior
const resetScrollAfterTimeout = () => {
    if (scrollResetTimeout) {
        clearTimeout(scrollResetTimeout);
    }

    scrollResetTimeout = setTimeout(() => {
        document.documentElement.scroll({
            behavior: 'smooth',
            top: 0,
            left: 0,
        });

        const activeElement = document.activeElement;

        if (
            activeElement &&
            ['input', 'textarea', 'select'].includes(
                activeElement.tagName.toLowerCase(),
            )
        ) {
            scrollIntoView(activeElement, {
                scrollMode: 'if-needed',
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }

        scrollResetTimeout = null;
    }, SCROLL_RESET_TIMEOUT_MILLIS);
};

const handleVisualViewportResize = () => {
    if (!window.visualViewport.height) {
        return;
    }

    document.getElementById('root').style.height =
        window.visualViewport.height + 'px';

    resetScrollAfterTimeout();
};

window.addEventListener('focus', () => resetScrollAfterTimeout(), true);

if (window.visualViewport) {
    window.visualViewport.addEventListener(
        'resize',
        handleVisualViewportResize,
    );
    handleVisualViewportResize();
}

AppRegistry.registerComponent('App', () => AppLoader);
AppRegistry.runApplication('App', {
    rootTag: document.getElementById('root'),
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
