import { colors, shadows } from '@penji/style-guide';
import React, { PureComponent } from 'react';
import {
    Platform,
    Animated,
    Easing,
    StyleSheet,
    StyleProp,
    View,
    ViewStyle,
} from 'react-native';
import { ShadowView } from './shadow-view';

const FULL_ROTATION = 360;

interface Props {
    background?: Background;
    color?: 'default' | 'white';
    style?: StyleProp<ViewStyle>;
}

type Background = 'default' | 'none';

interface State {
    loadingRotation: Animated.Value;
}

export class LoadingSpinner extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loadingRotation: new Animated.Value(0),
        };
    }

    componentDidMount() {
        this.startLoadingAnimation();
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    unmounted = false;

    startLoadingAnimation() {
        Animated.timing(this.state.loadingRotation, {
            toValue: 360,
            easing: Easing.linear,
            duration: 700,
            useNativeDriver: Platform.select({
                native: true,
                web: false,
                default: false,
            }),
        }).start(() => {
            if (!this.unmounted) {
                this.state.loadingRotation.setValue(0);
                this.startLoadingAnimation();
            }
        });
    }

    render() {
        const { background, color, style } = this.props;

        return (
            <Container background={background} style={style}>
                <Animated.Image
                    style={[
                        {
                            transform: [
                                {
                                    rotate: this.state.loadingRotation.interpolate(
                                        {
                                            inputRange: [0, FULL_ROTATION],
                                            outputRange: ['0deg', '360deg'],
                                        },
                                    ),
                                },
                            ],
                        },
                    ]}
                    source={
                        color === 'white'
                            ? require('@penji/frontend/assets/loading-spinner-white-transparent.png')
                            : require('@penji/frontend/assets/loading-spinner-blue-white.png')
                    }
                />
            </Container>
        );
    }
}

const Container = ({
    style,
    background,
    children,
}: {
    style: StyleProp<ViewStyle>;
    background?: Background;
    children: React.ReactNode;
}) =>
    background === 'none' ? (
        <View style={style}>{children}</View>
    ) : (
        <ShadowView
            shadow={shadows['medium']}
            style={[styles.container, style]}
        >
            {children}
        </ShadowView>
    );

const styles = StyleSheet.create({
    container: {
        width: 40,
        height: 40,
        borderRadius: 20,
        padding: 8,
        backgroundColor: colors['white'],
    },
});
