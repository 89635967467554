import { Box, Typography, Button } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { LoadingSpinner } from '@penji/frontend/pattern-library/components/elements/loading-spinner';
import { colors, fonts } from '@penji/style-guide';
import * as Sentry from '@sentry/react';
import React, { Suspense, lazy } from 'react';
import { StyleSheet, View } from 'react-native';

const LazyApp = lazy(() => import('./lazy-app'));

export const AppLoader = () => {
    return (
        <ThemeProvider theme={theme}>
            <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
                <Suspense
                    fallback={
                        <View style={styles.progressContainer}>
                            <LoadingSpinner />
                        </View>
                    }
                >
                    <LazyApp />
                </Suspense>
            </Sentry.ErrorBoundary>
        </ThemeProvider>
    );
};

const ErrorFallback = () => (
    <Box
        display='flex'
        flexGrow={1}
        alignItems='center'
        justifyContent='center'
    >
        <Box
            margin={2}
            flexDirection='column'
            display='flex'
            alignItems='center'
        >
            <Typography variant='body1' component='p'>
                Something went wrong, please try again later.
            </Typography>
            <Box marginTop={2}>
                <Button
                    variant='contained'
                    onClick={() => window.location.reload(true)}
                >
                    Retry
                </Button>
            </Box>
        </Box>
    </Box>
);

const styles = StyleSheet.create({
    progressContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors['gray-100'],
    },
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: colors['blue-700'],
        },
    },
    overrides: {
        MuiTabs: {
            root: {
                backgroundColor: colors['white'],
            },
            indicator: {
                backgroundColor: colors['blue-600'],
            },
        },
        MuiTab: {
            textColorInherit: {
                opacity: 1,
                color: colors['gray-600'],
                '&$selected': {
                    color: colors['blue-600'],
                },
            },
            wrapper: {
                ...fonts['demi-bold'],
                textTransform: 'none',
            },
        },
    },
});
