import React, { PureComponent } from 'react';
import { Platform, StyleProp, View, ViewStyle } from 'react-native';

type Props = {
    shadow:
        | {
              elevation?: number;
              boxShadow?: string;
          }
        | ViewStyle;
    style: StyleProp<ViewStyle>;
} & View['props'];

export class ShadowView extends PureComponent<Props> {
    render() {
        const { children, shadow, style, ...restProps } = this.props;

        let viewProps;

        if (Platform.OS === 'android') {
            const { elevation } = shadow;

            viewProps = {
                ...restProps,
                elevation,
                style,
            };
        } else if (Platform.OS === 'ios') {
            viewProps = {
                ...restProps,
                style: [shadow, style],
            };
        } else if (Platform.OS === 'web') {
            viewProps = {
                ...restProps,
                style: [shadow, style],
            };
        }

        return <View {...viewProps}>{children}</View>;
    }
}
